// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../res/img/svg/inlined-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".treeViewHeaderColumn.self,.treeViewHeaderColumn.total{text-align:right}.treeViewHeaderColumn.totalPercent{display:none}.treeViewFixedColumn.self{width:70px}.treeViewFixedColumn.icon{align-items:center;display:flex;flex-flow:column nowrap}.treeViewRowColumn.self,.treeViewRowColumn.timestamp,.treeViewRowColumn.total,.treeViewRowColumn.totalPercent{text-align:right}.treeBadge.divergent-inlining,.treeBadge.inlined{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}", "",{"version":3,"sources":["webpack://./src/components/calltree/CallTree.css"],"names":[],"mappings":"AAIA,uDAEE,gBACF,CAGA,mCACE,YACF,CAEA,0BACE,UACF,CAEA,0BAGE,kBAAmB,CAFnB,YAAa,CACb,uBAEF,CAEA,8GAIE,gBACF,CAEA,iDAEE,kDACF","sourcesContent":["/* This Source Code Form is subject to the terms of the Mozilla Public\n * License, v. 2.0. If a copy of the MPL was not distributed with this\n * file, You can obtain one at http://mozilla.org/MPL/2.0/. */\n\n.treeViewHeaderColumn.total,\n.treeViewHeaderColumn.self {\n  text-align: right;\n}\n\n/* The header for the totalPercent column is not visible */\n.treeViewHeaderColumn.totalPercent {\n  display: none;\n}\n\n.treeViewFixedColumn.self {\n  width: 70px;\n}\n\n.treeViewFixedColumn.icon {\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: center;\n}\n\n.treeViewRowColumn.total,\n.treeViewRowColumn.totalPercent,\n.treeViewRowColumn.self,\n.treeViewRowColumn.timestamp {\n  text-align: right;\n}\n\n.treeBadge.inlined,\n.treeBadge.divergent-inlining {\n  background: url(../../../res/img/svg/inlined-icon.svg);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
